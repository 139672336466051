<template>
  <v-autocomplete
    v-model="iVendorCollectorId"
    :items="items"
    :label="$t('vendor.collector')"
    :loading="loading"
    :menu-props="{ bottom: true }"
    clearable
    dense
    hide-details
    item-text="name"
    item-value="id"
    no-filter
    outlined
    v-bind="$attrs"
    @change="onChange"
    @update:search-input="onSearch"
    @click:clear="preLoad"
  ></v-autocomplete>
</template>

<script lang="ts">
import {
  VendorCollector,
  VendorCollectorCollection,
} from "@planetadeleste/vue-mc-gw";
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import { DebounceFunction } from "@/plugins/helpers";
import {
  debounce,
  differenceWith,
  filter,
  get,
  isEqual,
  isNil,
  set,
  toPairs,
} from "lodash";

@Component
export default class VendorCollectorSelect extends Vue {
  @VModel({ type: Number }) iVendorCollectorId!: number;
  @Prop({ type: [Boolean, String], default: "auto" }) readonly hideDetails!:
    | boolean
    | "auto";

  obCollection: VendorCollectorCollection = new VendorCollectorCollection();
  obSelected: VendorCollector | null | undefined = null;
  fnDebounceSearch!: DebounceFunction;
  sSearch = "";

  get loading() {
    return this.obCollection.loading;
  }

  get items() {
    return this.obCollection.getModelList();
  }

  get selected() {
    return this.iVendorCollectorId && this.obCollection.length
      ? this.obCollection.find({ id: this.iVendorCollectorId })
      : undefined;
  }

  get obFilters(): Record<string, any> {
    const obFilterData = { active: 1 };
    const sCollectionSearch = get(
      this.obCollection.get("filters", {}),
      "search"
    );

    // Search by words
    if (this.sSearch && !isEqual(this.sSearch, sCollectionSearch)) {
      set(obFilterData, "search", this.sSearch);
    }

    return obFilterData;
  }

  @Watch("obFilters")
  onCangeFilters(
    obNewData: Record<string, any>,
    obOldData?: Record<string, any>
  ) {
    let changes = differenceWith(
      toPairs(obNewData),
      toPairs(obOldData),
      isEqual
    );

    changes = filter(changes, (arItem: string[]) => {
      return arItem.length >= 2 && arItem[0] !== "search";
    }) as [string, any][];

    if (!changes.length) {
      return;
    }

    this.preLoad();
  }

  // @Watch("sSearch")
  onSearch(sValue: string) {
    this.sSearch = sValue;
    this.fnDebounceSearch();
  }

  async mounted() {
    await this.preLoad();
  }

  created() {
    this.fnDebounceSearch = debounce(this.search, 500);
  }

  async load() {
    if (
      !this.iVendorCollectorId ||
      (this.obSelected && this.obSelected.id === this.iVendorCollectorId)
    ) {
      return;
    }

    if (this.obCollection.length) {
      this.obSelected = this.obCollection.find({ id: this.iVendorCollectorId });
      if (this.obSelected) {
        this.onChange();
        return;
      }
    }

    this.obSelected = new VendorCollector(
      { id: this.iVendorCollectorId },
      this.obCollection
    );
    await this.obSelected.fetch();
    this.obCollection.add(this.obSelected);
    this.onChange();
  }

  async preLoad() {
    this.sSearch = "";
    this.obCollection = new VendorCollectorCollection();
    this.obCollection.filterBy(this.obFilters);
    await this.obCollection.limit(10).fetch();
    await this.load();
  }

  async search() {
    if (this.loading) {
      return;
    }

    if (!isNil(this.sSearch) && this.sSearch.length) {
      const obFilters = this.obCollection.get("filters", {});
      if (get(obFilters, "search") == this.sSearch) {
        return;
      }

      if (this.obCollection.find({ name: this.sSearch })) {
        return;
      }
    } else {
      await this.preLoad();

      return;
    }

    this.obCollection.filterBy(this.obFilters);
    this.obCollection.clear();
    this.obCollection.page(1);

    await this.obCollection.fetch();
  }

  onChange(iVendorCollector?: number) {
    if (
      iVendorCollector &&
      (iVendorCollector !== this.iVendorCollectorId ||
        (this.obSelected && this.obSelected.id !== iVendorCollector))
    ) {
      this.obSelected = this.obCollection.find({ id: iVendorCollector });
    }

    this.$emit("change", this.obSelected);
  }
}
</script>
